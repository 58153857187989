<template>
  <div>
    <div slot="header" class="clearfix">
      <span class="headerFont">{{ $t("ACTCourse.title") }}</span>
    </div>
    <div>
      <el-image
        src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/sat_part1.jpg"
        style="width: 100%; height: 100%"
      />
      <p>{{ $t("ACTCourse.desc1") }}</p>
    </div>
    <el-divider></el-divider>
    <div>
      <h4>{{ $t("ACTCourse.placementTest") }}</h4>
      <p>{{ $t("ACTCourse.placementTestDesc") }}</p>
    </div>
    <el-divider></el-divider>
    <div>
      <h4>{{ $t("ACTCourse.tailorMadeSmallGroupClasses") }}</h4>
      <p>{{ $t("ACTCourse.tailorMadeSmallGroupClassesDesc1") }}</p>
      <p>{{ $t("ACTCourse.tailorMadeSmallGroupClassesDesc2") }}</p>
    </div>
    <el-divider></el-divider>
    <div>
      <h4>{{ $t("ACTCourse.provenLearningMethods") }}</h4>
      <p>{{ $t("ACTCourse.provenLearningMethodsDesc") }}</p>
    </div>
    <el-row>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fas fa-exchange-alt"></i>
          </div>
          <h3>
            {{
              $t("ACTCourse.provenLearningMethodsPoints.interactiveLearning")
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.interactiveLearningDesc"
              )
            }}
          </p>
        </div>
      </el-col>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fab fa-slideshare"></i>
          </div>
          <h3>
            {{
              $t("ACTCourse.provenLearningMethodsPoints.2TeachersPerClass")
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.2TeachersPerClassDesc"
              )
            }}
          </p>
        </div>
      </el-col>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fas fa-tachometer-alt"></i>
          </div>
          <h3>
            {{
              $t("ACTCourse.provenLearningMethodsPoints.englishFundamentals")
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.englishFundamentalsDesc"
              )
            }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fas fa-history"></i>
          </div>
          <h3>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.dailyAfterClassReview"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.dailyAfterClassReviewDesc"
              )
            }}
          </p>
        </div>
      </el-col>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fas fa-comments"></i>
          </div>
          <h3>
            {{
              $t("ACTCourse.provenLearningMethodsPoints.studyGroupDiscussion")
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.studyGroupDiscussionDesc"
              )
            }}
          </p>
        </div>
      </el-col>
      <el-col :sm="8" style="text-align: center">
        <div>
          <div class="fa-icon">
            <i class="fa fa-users"></i>
          </div>
          <h3>
            {{
              $t("ACTCourse.provenLearningMethodsPoints.homeworkDiscussion")
            }}
          </h3>
          <p>
            {{
              $t(
                "ACTCourse.provenLearningMethodsPoints.homeworkDiscussionDesc"
              )
            }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div>
      <h4>{{ $t("ACTCourse.classSchedule") }}</h4>
      <p>{{ $t("ACTCourse.classScheduleDesc1") }}</p>
      <h5 class="pt-4">{{ $t("ACTCourse.classScheduleSubTitle") }}</h5>
      <p>{{ $t("ACTCourse.classScheduleDesc2") }}</p>
      <el-row class="mt-5">
        <el-col :sm="8" style="text-align:center">
          <div class="fa-icon">
            <i class="fas fa-chart-pie"></i>
          </div>
          <h3>{{ $t("ACTCourse.classSchedulePoints.part1") }}</h3>
          <p>{{ $t("ACTCourse.classSchedulePoints.part1Desc") }}</p>
        </el-col>
        <el-col :sm="16" style="text-align:center">
          <el-tabs v-model="activeName1" type="border-card">
            <el-tab-pane :label="$t('ACTCourse.scheduleA')" name="first">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part1_schedule_A.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleB')" name="second">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part1_schedule_B.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleC')" name="third">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part1_schedule_C.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="mt-5">
        <el-col :sm="8" style="text-align:center">
          <div class="fa-icon">
            <i class="fas fa-eye-dropper"></i>
          </div>
          <h3>{{ $t("ACTCourse.classSchedulePoints.part2") }}</h3>
          <p>{{ $t("ACTCourse.classSchedulePoints.part2Desc") }}</p>
        </el-col>
        <el-col :sm="16" style="text-align:center">
          <el-tabs v-model="activeName2" type="border-card">
            <el-tab-pane :label="$t('ACTCourse.scheduleA')" name="first">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part2_schedule_A.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleB')" name="second">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part2_schedule_B.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleC')" name="third">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part2_schedule_C.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="mt-5">
        <el-col :sm="8" style="text-align:center">
          <div class="fa-icon">
            <i class="fa fa-rocket"></i>
          </div>
          <h3>{{ $t("ACTCourse.classSchedulePoints.part3") }}</h3>
          <p>{{ $t("ACTCourse.classSchedulePoints.part3Desc") }}</p>
        </el-col>
        <el-col :sm="16" style="text-align:center">
          <el-tabs v-model="activeName3" type="border-card">
            <el-tab-pane :label="$t('ACTCourse.scheduleA')" name="first">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part3_schedule_A.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleB')" name="second">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part3_schedule_B.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
            <el-tab-pane :label="$t('ACTCourse.scheduleC')" name="third">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/part3_schedule_C.JPG"
                style="width: 100%; height: 100%"
              ></el-image>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <div>
      <h3>{{ $t("ACTCourse.originalMaterialsOfficialPractices") }}</h3>
      <p>{{ $t("ACTCourse.originalMaterialsOfficialPracticesDesc") }}</p>
      <ol>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.1") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.2") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.3") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.4") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.5") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.6") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.7") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.8") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.9") }}
        </li>
        <li>
          {{ $t("ACTCourse.originalMaterialsOfficialPracticesPoint.10") }}
        </li>
      </ol>
    </div>
    <el-divider></el-divider>
    <div>
      <el-row>
        <el-col :sm="16">
          <h3>{{ $t("ACTCourse.teacherStudentInteraction") }}</h3>
          <br />
          <p>{{ $t("ACTCourse.teacherStudentInteractionDesc") }}</p>
        </el-col>
        <el-col :sm="8" style="text-align:center">
          <div class="fa-icon" style="font-size: 130px">
            <i class="fas fa-exchange-alt"></i>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <div>
      <el-row>
        <h3>{{ $t("ACTCourse.heavyWorkload") }}</h3>
        <el-col :sm="8" style="text-align:center">
          <div class="fa-icon" style="font-size: 130px">
            <i class="fas fa-chart-line"></i>
          </div>
        </el-col>
        <el-col :sm="16">
          <br />
          <p>{{ $t("ACTCourse.heavyWorkloadDesc") }}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName1: "first",
      activeName2: "first",
      activeName3: "first"
    };
  }
};
</script>

<style lang="scss" scoped>
.headerFont {
  font-size: 32px;
  color: #42a16a;
  font-weight: 600;
}
.fa-icon {
  color: #42a16a;
  font-size: 72px;
}
</style>
